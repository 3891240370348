import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, main } from '../../content/dokumenty'
import { useLangContext } from '../../context/lang.context'
import Intro from '../../components/Intro'
import Main from '../../components/Main'
import Events from '../../components/Events'

const files = {
  pl: [
    {
      title: 'Zlecenie - Transport Morski',
      desc: 'Wersja polska',
      link: '/pdf/zlecenie-transport-morski.pdf',
      targetBlank: true,
    },
    {
      title: 'Zlecenie - Transport Lotniczy',
      desc: 'Wersja polska',
      link: '/pdf/zlecenie-transport-lotniczy.pdf',
      targetBlank: true,
    },
    {
      title: 'Zlecenie - Transport Drogowy',
      desc: 'Wersja polska',
      link: '/pdf/zlecenie-transport-drogowy.pdf',
      targetBlank: true,
    },
    {
      title: 'Upoważnienie do odprawy celnej',
      desc: 'Wersja polska',
      link: '/pdf/upowaznienie-do-odprawy-celnej_pl.pdf',
      targetBlank: true,
    },
    {
      title: 'Upoważnienie do odprawy celnej',
      desc: 'Wersja angielska',
      link: '/pdf/upowaznienie-do-odprawy-celnej_en.pdf',
      targetBlank: true,
    },
    {
      title: 'Ogólnopolskie Warunki Spedycyjne',
      desc: 'Wersja polska',
      link: '/pdf/opws_pl.pdf',
      targetBlank: true,
    },
    {
      title: 'Ogólne Warunki Przewozu',
      desc: 'Wersja polska',
      link: '/pdf/owp_pl.pdf',
      targetBlank: true,
    },
    {
      title: 'Incoterms 2020',
      desc: 'Wersja polska',
      link: '/pdf/incoterms-2020_pl.pdf',
      targetBlank: true,
    },
  ],
  en: [
    {
      title: 'Customs Clearance Authorization',
      desc: 'English version',
      link: '/pdf/upowaznienie-do-odprawy-celnej_en.pdf',
      targetBlank: true,
    },
    {
      title: 'General Terms and Conditions of Transport',
      desc: 'English version',
      link: '/pdf/owp_en.pdf',
      targetBlank: true,
    },
  ],
  ua: [
    {
      title: 'Дозвіл на митне оформлення',
      desc: 'Англомовна версія',
      link: '/pdf/upowaznienie-do-odprawy-celnej_en.pdf',
      targetBlank: true,
    },
    {
      title: 'Загальні положення та умови транспортування',
      desc: 'Англомовна версія',
      link: '/pdf/owp_en.pdf',
      targetBlank: true,
    },
  ],
}

const Dokumenty = () => {
  const { lang } = useLangContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/dokumenty.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Przeglądaj',
      en: 'Explore',
      ua: 'ПЕРЕГЛЯНУТИ',
    },
    action: 'SCROLL',
  }

  const buttonSecond = {
    text: {
      pl: 'Ochrona Danych',
      en: 'Data Protection',
      ua: 'ДЗАХИСТ ДАНИХ',
    },
    link: '/company/data-protection/',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
        noIndex
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={image.childImageSharp}
        position='bottom'
      />
      <Main h={1} title={main.title[lang]} article={main.html[lang]}>
        <Events data={files[lang]} />
      </Main>
    </Layout>
  )
}

export default Dokumenty
